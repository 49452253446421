.container.testimonials__container {
    width: 60%;
    padding-bottom: 4rem;
    max-width: 900px;
}
.client__avatar {
    width: 6rem;
    height: auto;
    overflow: hidden;
    margin: 0 auto 1rem;
}
.stars {
    color: gold;
    margin: 0 10px;
    text-align: center;
    font-size: 18px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}
.testimonial {
    background: var(--color-bg-variant);
    text-align: center;
    padding: 2rem 4rem;
    border-radius: 2rem;
    user-select: none;
}
.client__review {
    color: var(--color-light);
    font-weight: 300;
    display: block;
    margin: 0.8rem auto 0;
}
.clients__name {
    font-family: 'Play', sans-serif;
    color: var(--color-white);
    font-size: 1.25rem;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--color-primary);
}

/* ==========  MEDIA QUERIES (TABLET'S) ========== */
@media screen and (max-width: 1024px) {
    .container.testimonials__container {
        width: 60%;
    }

}
/* ==========  MEDIA QUERIES (MOBILE) ========== */
@media screen and (max-width: 600px) {
    .container.testimonials__container {
        width: var(--container-width-sm);
    }
    .client__review {
        width: var(--container-width-sm);
    }
}