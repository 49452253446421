.archive-header__container {
    text-align: center;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
}
.archive-header {
    height: auto;
    padding-top: 7rem;;
    overflow: hidden;
}
.archive-header__logo {
    position: absolute;
    top: 1.2rem;
    left: 1.2rem;
    width: 6rem;
    height: auto;
}

/* ==========  MEDIA QUERIES (TABLET'S) ========== */
@media screen and (max-width: 1024px) {
    .archive-header__logo {
        width: 4rem;
        height: auto;
    }
}
/* ==========  MEDIA QUERIES (MOBILE) ========== */
@media screen and (max-width: 600px) {

}