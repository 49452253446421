.archive__footer {
    background: transparent;
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
    color: var(--color-primary);   
}
.archive__footer a {
    color: var(--color-primary);   
}
.archive__footer-footer__logo {
    margin-bottom: 2rem;
    display: inline-block;
}
.archive__footer-footer__logo img {
    width: 15%;
    margin: 0 auto;
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-top: 5px;
}
@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 15px); }
    100%   { transform: translate(0, -0px); }   
}

.archive__footer-footer__socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 3rem;
}

.archive__footer-footer__socials a {
    background: var(--color-primary);
    color: var(--color-bg);
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
}
.archive__footer-footer__socials a:hover {
    background: transparent;
    color: var(--color-primary);
    border-color: var(--color-primary);
}
.archive__footer-footer__copyright {
    margin-bottom: 0;
    color: var(--color-primary);
}
.archive__footer-footer__privacy-policy {
    margin-bottom: 1rem;
    color: var(--color-primary);
    font-size: 0.8rem;
}


/* ==========  MEDIA QUERIES (MOBILE) ========== */
@media screen and (max-width: 600px) {
    .archive__footer-footer__socials {
        margin-bottom: 2.6rem;
        overflow: hidden;
    }
}
/* ==========  MEDIA QUERIES (SMALL-MOBILE) ========== */
@media screen and (max-width: 320px) {
    .archive__footer-footer__socials {
        gap: 0.5rem;
    }
}