#otherProjects {
    margin-top: 4rem;
}

.otherProjects__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

#otherProjects-link {
    margin: 0.8rem auto 3rem;
    display: block;
    text-align: center;
}

.otherProjects__item {
    background: var(--color-bg-variant-two);
    border-radius: 1rem;
    padding: 0.5rem; 
    border: 1px solid transparent;
    transition: var(--transition);
}
.otherProjects__item:hover {
    border-color: var(--color-primary-variant);
    transform: translate(0, -6px);
}

.otherProjects__item h3 {
    padding: 0.8rem 0 0;
}
.otherProjects__item p {
    padding: 0.8rem 0 1rem;
    min-height: 5rem;
}

.otherProjects__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.other__projects-tech {
    color: var(--color-light);
    font-size: 0.8rem;
}


/* ==========  MEDIA QUERIES (TABLET'S) ========== */
@media screen and (max-width: 1024px) {
    .otherProjects__container {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }
    .otherProjects__item-image {
        overflow: hidden;
        height: 12rem;
        width: 100%;
    }
}

/* ==========  MEDIA QUERIES (MOBILE) ========== */
@media screen and (max-width: 600px) {
    .otherProjects__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}