
.archive__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}
.archive__item {
    background: var(--color-bg-variant-two);
    border-radius: 1rem;
    padding: 0.5rem; 
    border: 1px solid transparent;
    transition: var(--transition);
}
.archive__item:hover {
    border-color: var(--color-primary-variant);
    transform: translate(0, -6px);
}
.archive__item-image {
    overflow: hidden;
}


#archive {
    margin-top: 4rem;
}
#archive h1 {
    font-family: 'Righteous';
    font-size: 3rem;
    text-align: left;
    color: var(--color-primary);
}
#archive h2 {
    font-family: 'Play', sans-serif;
    color: var(--color-light);
}

.archive__title {
    grid-column: 1 / span 3;
    text-align: center;
}
.archive__title h2 {
    margin: 2rem auto;
}

.archive__item h3 {
    padding: 0.8rem 0 0;
}
.archive__item p {
    padding: 0.8rem 0 1rem;
    min-height: 5rem;
}

.archive__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.archive-tech {
    color: var(--color-light);
    font-size: 0.8rem;
}
#apps-description {
    min-height: 8rem;
}




/* ==========  MEDIA QUERIES (TABLET'S) ========== */
@media screen and (max-width: 1024px) {
    .archive__container {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }
    .archive-header__logo {
        width: 4rem;
        height: auto;
    }
    .archive__title {
        grid-column: 1 / span 2;
    }
}
/* ==========  MEDIA QUERIES (MOBILE) ========== */
@media screen and (max-width: 600px) {
    .archive__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    .archive__title {
        grid-column: 1;
    }
}
