.about__container {
    display: grid;
    grid-template-columns: 30% 55%;
    gap: 15%;
    overflow: hidden;
}
.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(
        45deg, 
        transparent, 
        var(--color-primary),
        transparent
        );
    display: grid;
    place-items: center;
    justify-content: center;
    border: none;
}
.about__me_img {
    border-radius: 2rem;
    overflow: hidden;
    transition: var(--transition);
}
.about__me_img.visible {
    transform: rotate(10deg);
    transition-delay: 2.9s;
}

.about__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.about__card {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 1.5rem;
    text-align: center;
    transition: var(--transition);
    opacity: 0;
    filter: blur(5px);
    transform: translate(100%);
    white-space: nowrap;
}
.about__card:first-child {
    transition-delay: 400ms;
}
.about__card:nth-child(2) {
    transition-delay: 700ms;
}
.about__card:last-child {
    transition-delay: 900ms;
}
.about__card.visible {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}

.about__card-p {
    margin: 2rem 0 2.6rem;
    font-family: 'Play', sans-serif;
    font-weight: 400;
    color: var(--color-light);

    transition: var(--transition);
    opacity: 0;
    filter: blur(5px);
    transform: translate(100%);
    transition-delay: 1100ms;
}
.about__card-p.visible {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}

#about__card-btn {
    transition: var(--transition);
    opacity: 0;
    filter: blur(5px);
    transform: translate(100%);
    transition-delay: 1400ms;
}
#about__card-btn.visible {
    opacity: 1;
    filter: blur(0);
    transform: translate(0);
}


@media (prefers-reduced-motion) {
    .about__card, .about__card-p, #about__card-btn {
        opacity: 1;
        transition: none;
        filter: blur(0);
    }
}


.about__icon {
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}
.card-header {
    font-size: 0.95rem;
}
.about__card small {
    font-size: 0.7rem;
    color: var(--color-light);
}

/* ==========  MEDIA QUERIES (TABLET'S) ========== */
@media screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }
    .about__me {
        width: 50%;
        margin: 2rem auto 4rem
    }
    .about__card-p {
        margin: 1rem 0 1.5rem;
    }
}

/* ==========  MEDIA QUERIES (MOBILE) ========== */
@media screen and (max-width: 600px) {
    .about__me {
        width: 60%;
        margin: 0 auto 3rem;
    }
    .about__card {
        padding: 1rem;
    }
    .about__cards {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0.25rem;
    }
    .about__content {
        text-align: center;
    }
    .about__card-p {
        margin: 1.5rem 0;
    }
}

/* ==========  MEDIA QUERIES (SMALL-MOBILE) ========== */
@media screen and (max-width: 320px) {
    .about__cards {
        grid-template-columns: 1fr;
        gap: 0.25rem;
    }
}
