
.header__container {
    text-align: center;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
}
.header__container .text-light {
    padding: 0 1rem;
}
header {
    height: 100%;
    padding-top: 7rem;;
    overflow: hidden;
}
.header__logo {
    position: absolute;
    top: 1.2rem;
    left: 1.2rem;
    width: 6rem;
    height: auto;
}

.header-btn {
    display: flex;
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
    gap: 0.5rem;
    transition: var(--transition);
}


.header__container h1 {
    font-family: 'sans-serif';
    color: var(--color-white);
}

/* CTA */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* SOCIALS */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    font-size: 1.4rem;
    position: absolute;
    left: 0;
    bottom: 0rem;
}
.header__socials::after {
    content: "";
    width: 1px;
    height: 6rem;
    background: var(--color-primary);
}
/* SCROLL DOWN */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 6rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 1.2rem;
}

/* My Image */
.my_image {
    width: 100%;
    height: auto; 
    position: relative;
    margin-top: 2rem;
}

.text-blue {
    color: var(--color-primary);
}
.header-text {
    color: var(--color-white);
}

/* ==========  MEDIA QUERIES (TABLET'S) ========== */
@media screen and (max-width: 1024px) {
    .header__logo {
        width: 4rem;
        height: auto;
    }
}
/* ==========  MEDIA QUERIES (MOBILE) ========== */
@media screen and (max-width: 600px) {
    .scroll__down {
        display: none;
    }
    /* SOCIALS */
    .header__socials {
        display: none;
    }    

    /* My Image */
    .my_image {
        width: 100%;
        height: 22rem; 
    }
}


/* ==========  MEDIA QUERIES (SMALL-MOBILE) ========== */
@media screen and (max-width: 320px) {
    .header-btn {
        display: block;
        width: 50%;
        text-align: right;
    }
    #btn__download {
        margin-bottom: 0.5rem;
    }    
}