
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style-type: none;
    text-decoration: none;
}
:root {
    /*--color-bg:#1f1f38;*/
    --color-bg:#242454;
    --color-bg-variant:linear-gradient(to bottom right, #130295, #4db5ff);
    --color-bg-variant-reversed:linear-gradient(to bottom right, #4db5ff, #130295);
    --color-bg-variant-two:linear-gradient(to bottom right,  #130295, #000000);
    --color-primary:#4db5ff;
    /*--color-primary-variant:rgba(77,191,255, 0.4);*/
    --color-primary-variant:rgba(77,191,255, 0.4);
    --color-white: #ffff;
    --color-light: rgba(255, 255, 255, 0.6);
    --transition: all 400ms ease;

    --container-width-lg: 82%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}
html {
    scroll-behavior: smooth;
}
::-webkit-scrollbar {
    display: none;
}
body {
    background-color: var(--color-bg);
    color: var(--color-white);
    font-family: 'Poppins', sans-serif;
    line-height: 1.7;
    /*background-image: url(../src/assets/bg-texture.png);*/
}
/* General styles */
.container {
    width: var(--container-width-lg);
    margin: 0 auto;
    max-width: 1400px;
}

/* HTML Meta noscript tag */
.noscript-html-doc {
    display: none;
}

h1, h2, h3, h4, h5 {
    font-weight: 500px;
}
h5 {
    font-family: 'Play', sans-serif;
    color: var(--color-light);
}
h3 {
    font-family: 'Play', sans-serif;
    font-weight: 700;
}
h2 {
    font-family: 'Righteous', cursive;
}
p {
    font-family: 'Play', sans-serif;
    font-weight: 400;
    color: var(--color-light);
}
h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 8rem;
}
section > h2, .second-heading {
    text-align: center;
    color: var(--color-light);
}
section > h2 {
    color: var(--color-primary);
}
.second-heading {
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}
a:hover {
    color: var(--color-white);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}
.btn:hover {
    background-color: var(--color-primary);
    color: var(--color-bg);
    border-color: transparent;
}
.btn-primary {
    background-color: var(--color-primary);
    color: var(--color-bg);
}
.btn-primary:hover {
    background-color: transparent;
    color: var(--color-primary);
    border-color: var(--color-primary);
}

img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* ==========  MEDIA QUERIES (TABLET'S) ========== */
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }
    section {
        margin-top: 6rem;
    }
}
/* ==========  MEDIA QUERIES (MOBILE) ========== */
@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }
    .second-heading {
        margin-bottom: 2rem;
    }
}