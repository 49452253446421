.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.portfolio__item {
    background: var(--color-bg-variant-two);
    border-radius: 1rem;
    padding: 0.5rem; 
    border: 1px solid transparent;
    transition: var(--transition);
}
.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    transform: translate(0, -6px);
}

.portfolio__item h3 {
    padding: 0.8rem 0 0;
}
.portfolio__item p {
    padding: 0.8rem 0 1rem;
    min-height: 5rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.portfolio-tech {
    color: var(--color-light);
    font-size: 0.8rem;
}


/* ==========  MEDIA QUERIES (TABLET'S) ========== */
@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }
}

/* ==========  MEDIA QUERIES (MOBILE) ========== */
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}