.services__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.service__item {
    background: var(--color-bg-variant-two);
    padding: 0.8rem;
    border-radius: 1rem;
    border: 1px solid transparent;
    transition: 1s;
    opacity: 0;
    filter: blur(5px);
}
.service__item:first-child, .service__item:nth-child(2) {
    transition-delay: 300ms;
}

.service__item:nth-child(3), .service__item:last-child {
    transition-delay: 600ms;
}

.service__item.visible {
    opacity: 1;
    filter: blur(0);
}
.service__item-image img {
    width: auto;
    height: 80px;

}
.service__item h3 {
    margin: 1.2rem 0;
}

.service__item-description {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

/* ==========  MEDIA QUERIES (TABLET'S) ========== */
@media screen and (max-width: 1024px) {
    .services__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* ==========  MEDIA QUERIES (MOBILE) ========== */
@media screen and (max-width: 600px) {
    .services__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}